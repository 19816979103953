import React from 'react';
import Login from '@react-login-page/base';

import app from './fire';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { signInWithEmailAndPassword } from 'firebase/auth';
import logo from './assets/logo.jpeg';

const analytics = getAnalytics(app);
const auth = getAuth(app);

const LoginPage = ({ setToken, setIsLoading }) => {
    const handle = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const { username, password } = Object.fromEntries(formData);

        setIsLoading(true);
        signInWithEmailAndPassword(auth, username, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                setToken(userCredential);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;

                alert(errorCode);
                console.log(errorCode, errorMessage)
            }).finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <form onSubmit={handle}>
            <Login style={{ background: 'white', height: '100vh' }}>
                <Login.Title visible={false} />
                <Login.Submit>Login</Login.Submit>
                <Login.Logo>
                    <img src={logo} />
                </Login.Logo>
                <Login.Footer style={{ color: 'black' }}>
                    Not a member? contact admin
                </Login.Footer>
            </Login>
        </form>
    );
};
export default LoginPage;