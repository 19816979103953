import './App.css';
import Login from './Login';
import Dashboard from './Dashboard';
import { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://d33913db08cdfad17a08a5a985c702fd@o4507430223872000.ingest.us.sentry.io/4507430224265216',
  environment: process.env.NODE_ENV ?? 'production',
  integrations: [
    Sentry.feedbackIntegration({
      colorScheme: 'system',
      isEmailRequired: true,
    }),
  ],
});

const firebaseConfig = {
  apiKey: "AIzaSyBW5aRL5tr4wiSMci5wYjxvTKM2_cq8hm0",
  authDomain: "twipiapp.firebaseapp.com",
  projectId: "twipiapp",
  storageBucket: "twipiapp.appspot.com",
  messagingSenderId: "320714811355",
  appId: "1:320714811355:web:3c53588a78ac881d55e9fe",
  measurementId: "G-3GDGGYE852"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [initializing, setInitializing] = useState(true);

  // Handle user state changes
  function onAuthStateChanged(user) {
    setToken(user);
    if (initializing) setInitializing(false);
  }

  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged);

    return subscriber;
  }, []);

  if (initializing) return null;

  return (
    <>
      {isLoading && (<div className="loader"><span className="spinner"></span></div>)}
      {!token ? (<Login setToken={setToken} setIsLoading={setIsLoading}/>) : (<Dashboard setIsLoading={setIsLoading}/>)}
    </>
  );
}

export default App;
