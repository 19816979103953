import logo from './assets/logo.jpeg';
import './Dashboard.css';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { useState, useEffect } from 'react';
import {QRCodeSVG} from 'qrcode.react';
import Tabs from '@mui/material/Tabs';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PieChart } from '@mui/x-charts/PieChart';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { BarChart } from '@mui/x-charts/BarChart';
import moment from 'moment';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import app from './fire';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, signOut } from 'firebase/auth';
const host = process.env.REACT_APP_API_HOST;
const token = process.env.REACT_APP_API_TOKEN;

const analytics = getAnalytics(app);
const auth = getAuth(app);


function Dashboard({ setIsLoading }) {
  const [value, setValue] = useState(0);
  const [isCompanyCreationFormOpen, setIsCompanyCreationFormOpen] = useState(false);
  const [isCodeCreationFormOpen, setIsCodeCreationFormOpen] = useState(false);
  const [isCompanyEditionFormOpen, setIsCompanyEditionFormOpen] = useState(false);
  const [isCodeEditionFormOpen, setIsCodeEditionFormOpen] = useState(false);
  const [companyToEdit, setCompanyToEdit] = useState(null);
  const [codeToEdit, setCodeToEdit] = useState(null);
  const [isCodeDetailOpen, setIsCodeDetailOpen] = useState(false);
  const [codeDetail, setCodeDetail] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [codes, setCodes] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setIsLoading(true);
    getCompanies();
    getCodes();
    getFeedbacks();
  }, []);

  const getCompanies = () => {
    fetch(`${host}/company`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async (res) => {
      if (res.ok) {
        const { data } = await res.json();
        setCompanies(data);
        setIsLoading(false);
      }
    });
  };

  const getCodes = () => {
    fetch(`${host}/code`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async (res) => {
      if (res.ok) {
        const { data } = await res.json();
        setCodes(data);
        setIsLoading(false);
      }
    });
  };

  const getFeedbacks = () => {
    fetch(`${host}/feedback`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async (res) => {
      if (res.ok) {
        const { data } = await res.json();
        setFeedbacks(data);
        setIsLoading(false);
      }
    });
  };

  const createCompany = () => {
    setIsLoading(true);
    setIsCompanyCreationFormOpen(true);
  };

  const handleCompanyCreation = (ev) => {
    setIsLoading(true);
    ev.preventDefault();
    const name = ev.target.name.value;
    const description = ev.target.description.value;
    const email = ev.target.email.value;
    const phone = ev.target.phone.value;
    const googlePlaceId = ev.target.googlePlaceId.value;
    
    fetch(`${host}/company`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ name, description, email, phone, googlePlaceId })
    }).then(async (res) => {
      if (res.ok) {
        handleClose(true);
      }
    }).finally(() => setIsLoading(false));
  };

  const handleEditCompany = (company) => {
    setIsCompanyEditionFormOpen(true);
    setCompanyToEdit(company);
  };

  const handleEditCode = (code) => {
    setIsCodeEditionFormOpen(true);
    setCodeToEdit(code);
  };

  const editCompany = (ev) => {
    setIsLoading(true);
    ev.preventDefault();
    const name = ev.target.name.value;
    const description = ev.target.description.value;
    const email = ev.target.email.value;
    const phone = ev.target.phone.value;
    const googlePlaceId = ev.target.googlePlaceId.value;
    
    fetch(`${host}/company`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ name, description, email, phone, googlePlaceId })
    }).then(async (res) => {
      if (res.ok) {
        handleClose(true);
      }
    }).finally(() => setIsLoading(false));
  };

  const editCode = (ev) => {
    setIsLoading(true);
    ev.preventDefault();
    const description = ev.target.description.value;
    const type = ev.target.type.value;
    const imageUrl = ev.target.imageUrl.value;
    const googlePlaceId = ev.target.googlePlaceId.value;
    const uuid = ev.target.uuid.value;
    
    fetch(`${host}/code/${uuid}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ type, description, imageUrl, googlePlaceId })
    }).then(async (res) => {
      if (res.ok) {
        handleClose(true);
      }
    }).finally(() => setIsLoading(false));
  };

  const handleCodeCreation = (ev) => {
    setIsLoading(true);
    ev.preventDefault();
    const name = ev.target.name.value;
    const description = ev.target.description.value;
    const type = ev.target.type.value;
    const imageUrl = ev.target.imageUrl.value;
    const googlePlaceId = ev.target.googlePlaceId.value;
    
    fetch(`${host}/code`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ name, description, type, imageUrl, googlePlaceId })
    }).then(async (res) => {
      if (res.ok) {
        handleClose(true);
      }
    }).finally(() => setIsLoading(false));
  };

  const createCode = () => {
    setIsLoading(true);
    setIsCodeCreationFormOpen(true);
  };

  const logout = () => {
    signOut(auth);
  };

  const handleClose = (reload = false) => {
    setIsCompanyEditionFormOpen(false);
    setIsCodeEditionFormOpen(false);
    setIsCompanyCreationFormOpen(false);
    setIsCodeCreationFormOpen(false);
    setIsLoading(false);

    if (reload) {
      getCompanies();
      getCodes();
      getFeedbacks();
    }
  };

  const CompanyCreationForm = ({ handleClose, open }) => {
    return (<Dialog className="dialog" onClose={handleClose} open={open}>
        <DialogTitle>Create new company</DialogTitle>
        <form onSubmit={handleCompanyCreation}>
          <DialogContent>
            <DialogContentText>
              To create a new company, please indicate the name of it
            </DialogContentText>
            <TextField label="Name" autoFocus fullWidth required variant="standard" type="text" name="name" />
            <TextField label="Description" fullWidth variant="standard" type="text" name="description" />
            <TextField label="Phone" fullWidth variant="standard" type="text" name="phone" />
            <TextField label="Email" fullWidth variant="standard" type="text" name="email" />
            <TextField label="Google place ID" fullWidth variant="standard" type="text" name="googlePlaceId" />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Create</Button>
          </DialogActions>
        </form>
      </Dialog>);
  };

  const CompanyEditionForm = ({ handleClose, open, company }) => {
    if (!company) return;

    return (<Dialog className="dialog" onClose={handleClose} open={open}>
    <DialogTitle>Edit company {company.name}</DialogTitle>
    <form onSubmit={editCompany}>
      <DialogContent>
        <TextField label="Name" defaultValue={company.name} autoFocus fullWidth required variant="standard" type="text" name="name" />
        <TextField label="Description" defaultValue={company.description} fullWidth variant="standard" type="text" name="description" />
        <TextField label="Phone" defaultValue={company.phone} fullWidth variant="standard" type="text" name="phone" />
        <TextField label="Email" defaultValue={company.email} fullWidth variant="standard" type="text" name="email" />
        <TextField label="Google place ID" defaultValue={company.googlePlaceId} fullWidth variant="standard" type="text" name="googlePlaceId" />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit">Save</Button>
      </DialogActions>
    </form>
  </Dialog>);
  };

  const CodeCreationForm = ({ handleClose, open }) => {
    const def = companies[0]?.name;

    return (<Dialog className="dialog" onClose={handleClose} open={open}>
    <DialogTitle>Create new code</DialogTitle>
    <form onSubmit={handleCodeCreation}>
      <DialogContent>
        <DialogContentText>
          To create a new code, please indicate the name of company and the type of the code
        </DialogContentText>
        <InputLabel sx={{mt: 2}} id="companyName">Company name</InputLabel>
        <Select
          labelId="companyName"
          label="Company name"
          name="name"
          sx={{mb: 2}}
          defaultValue={def}
        >
          {companies?.map(company => (<MenuItem value={company.name}>{company.name}</MenuItem>))}
        </Select>
        <TextField sx={{mb: 2}} label="Description" fullWidth variant="standard" type="text" name="description" />
        <TextField sx={{mb: 2}} label="Image url" fullWidth variant="standard" type="text" name="imageUrl" />
        <TextField label="Google place ID" fullWidth variant="standard" type="text" name="googlePlaceId" />
        <InputLabel sx={{mb: 2}} id="codeType">Type</InputLabel>
        <Select
          labelId="codeType"
          id="demo-simple-select"
          label="Type"
          defaultValue="RESTAURANT"
          name="type"
        >
          <MenuItem value="RESTAURANT">Restaurant</MenuItem>
          <MenuItem value="HOTEL">Hotel</MenuItem>
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit">Create</Button>
      </DialogActions>
    </form>
  </Dialog>);
  };

  const CodeEditionForm = ({ handleClose, open, code }) => {
    if (!code) return;

    return (<Dialog className="dialog" onClose={handleClose} open={open}>
    <DialogTitle>Edit code {code.company.name}</DialogTitle>
    <form onSubmit={editCode}>
      <DialogContent>
        <input hidden value={code.uuid} name="uuid" />
        <TextField sx={{mb: 2}} label="Description" defaultValue={code.description} fullWidth variant="standard" type="text" name="description" />
        <TextField sx={{mb: 2}} label="Image url" defaultValue={code.imageUrl} fullWidth variant="standard" type="text" name="imageUrl" />
        <TextField label="Google place ID" defaultValue={code.googlePlaceId} fullWidth variant="standard" type="text" name="googlePlaceId" />
        <InputLabel sx={{mb: 2}} id="codeType">Type</InputLabel>
        <Select
          labelId="codeType"
          id="demo-simple-select"
          label="Type"
          defaultValue={code.type}
          name="type"
        >
          <MenuItem value="RESTAURANT">Restaurant</MenuItem>
          <MenuItem value="HOTEL">Hotel</MenuItem>
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit">Save</Button>
      </DialogActions>
    </form>
  </Dialog>);
  };

  const CodeDetail = ({ handleClose, open, code = {} }) => {
    const dataRef = [6,5,4,3,2,1,0].map(diff => moment().subtract(diff, 'days'));
    const xLabels = dataRef.map(date => moment(date).fromNow());
    const pData = dataRef.map(date => code?.scans?.filter(scan => 
      moment(scan.timestamp).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')).length);

    return (<Dialog className="dialog" onClose={handleClose} open={open}>
    <DialogTitle>Details</DialogTitle>
      <DialogContent>
      <BarChart
        width={500}
        height={300}
        series={[
          { data: pData, label: 'Scans', type: 'bar' }
        ]}
        xAxis={[{ data: xLabels, scaleType: 'band' }]}
      />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
  </Dialog>);
  };

  const stringToColor = (string) => {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
  
    return color;
  }
  
  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name[0].toUpperCase()}`,
    };
  }

  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const BasicInfo = (props) => {
    const totalScans = codes?.reduce((acc, curr) => acc+=curr.scans.length || 0, 0);
    const scansRatio = Math.floor(feedbacks?.length / totalScans * 100);

    return (<Grid container justifyContent="center" alignItems="center">
      <Grid xs={12} sm={6} className="elem" justifyContent="center" alignItems="center">
        <h3>Metrics</h3>
        <PieChart
          series={[
            {
              data: [
                { id: 0, value: companies?.length || 0, label: 'Companies' },
                { id: 1, value: codes?.length || 0, label: 'Codes' },
                { id: 2, value: feedbacks?.length || 0, label: 'Tips' },
              ],
            },
          ]}
          width={400}
          height={200}
        />
      </Grid>
    <Grid xs={12} sm={6} className="elem" justifyContent="center" alignItems="center">
      <h3>Pay ratio</h3>
      <Gauge
        value={scansRatio}
        startAngle={-110}
        width={200}
        aria-valuetext="Pay ratio"
        height={200}
        endAngle={110}
        sx={{
          [`& .${gaugeClasses.valueText}`]: {
            fontSize: 30,
            transform: 'translate(0px, 0px)',
          },
        }}
        text={
          ({ value, valueMax }) => `${value} / ${valueMax}`
        }
      />
    </Grid>
    </Grid>);
  };

  const companyDetail = function(company) {
    const companyCodes = codes?.filter((code) => company._id === code?.company?._id);
    const companyFeedbacks = feedbacks?.filter((feedback) => company._id === feedback?.company?._id);
    const totalTips = companyFeedbacks?.reduce((acc, feed) => {
        acc +=feed.amount;

        return acc;
      }, 0);
    const averageStars = companyFeedbacks?.reduce((acc, feed) => {
      acc +=feed.stars;

      return acc;
    }, 0) / companyFeedbacks.length || 0;
    const totalScans = companyCodes?.reduce((acc, code) => {
      acc +=code.scans.length;

      return acc;
    }, 0);
    const payRatio = (companyFeedbacks.length / totalScans) * 100 || 0;

    return { averageStars, totalTips, totalScans, feedbacks: companyFeedbacks.length, payRatio };
  };

  const handleCodeDetail = (code) => {
    setCodeDetail(code);
    setIsCodeDetailOpen(true);
  };

  const handleCloseCodeDetail = () => {
    setIsCodeDetailOpen(false);
  };

  const getUrl = (code) => host + '/review?q=' + code.uuid.substring(0,8);

  const companyColumns = [
    {
      field: 'avatar',
      sortable: false,
      renderCell: (params) => (<Avatar className="avatar" {...stringAvatar(params.row.name)} />)
    },
    {
      field: 'id',
      headerName: 'Name',
      sortable: true,
    },
    {
      field: 'description',
      headerName: 'Description',
      sortable: false,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      sortable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      sortable: true,
    },
    {
      field: 'googlePlaceId',
      headerName: 'Google place',
      sortable: false,
    },
    {
      field: 'imageUrl',
      headerName: 'Photo',
      sortable: false,
      renderCell: (params) => (<img height="50" src={params.row.imageUrl} />)
    },
    {
      field: 'averageStars',
      headerName: 'Avg. stars',
      sortable: true,
      valueFormatter: (data) => Number(data).toFixed(2)
    },
    {
      field: 'totalTips',
      headerName: 'Total tips',
      sortable: true,
      valueFormatter: (data) => `${data}€`
    },
    {
      field: 'feedbacks',
      headerName: 'Feedbacks',
      sortable: true,
    },
    {
      field: 'totalScans',
      headerName: 'Total scans',
      sortable: true,
    },
    {
      field: 'payRatio',
      headerName: 'Pay ratio',
      sortable: true,
      valueFormatter: (data) => `${Number(data).toFixed(2)}/100`
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: (params) => (<><Button onClick={() => handleEditCompany(params.row)}>Edit</Button><Button target="_blank" href={`${host}/report/${params.row.email}`} title="">Reports</Button></>)
    }
  ];

  const codeColumns = [
    {
      field: 'qr',
      headerName: 'Qr',
      sortable: false,
      width: 150,
      resizable: false,
      renderCell: (params) => (<QRCodeSVG level="L" value={getUrl(params.row)} />)
    },
    {
      field: 'companyName',
      headerName: 'Company',
      sortable: true,
    },
    {
      field: 'type',
      headerName: 'Type',
      sortable: true,
    },
    {
      field: 'imageUrl',
      headerName: 'Image',
      sortable: false,
      width: 200,
      resizable: false,
      renderCell: (params) => (<img style={{'width': '100%','height': 'auto'}} src={params.row.imageUrl} />)
    },
    {
      field: 'description',
      headerName: 'Description',
      sortable: false,
    },
    {
      field: 'googlePlaceId',
      headerName: 'Google Place',
      sortable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 400,
      align: 'right',
      renderCell: (params) => (
      <>
        <Button onClick={() => handleEditCode(params.row)}>Edit</Button>
        <Button onClick={() => handleCodeDetail(params.row)}>Stats</Button>
        <Button href={getUrl(params.row)} target="_blank" alt="">Open url</Button>
        <Button target="_blank" href={`${host}/code/${params.row.uuid}/download`} title="download printable PDF">Download PDF</Button>
      </>)
    }
  ];

  const codeMapper = (code) => {
    return {
      ...code,
      id: code._id,
      companyName: code.company.name
    };
  };
  const companyMapper = (company) => {
    const { averageStars, totalTips, feedbacks, totalScans, payRatio } = companyDetail(company);

    return {
      ...company,
      id: company.name,
      averageStars,
      totalTips,
      feedbacks,
      totalScans,
      payRatio
    };
  };

  return (
    <>
      <div className="App">
        <header>
          <div>
            <img className="logo" src={logo} alt="twipi" />
            <h1>Management</h1>
          </div>
          <Button id="logout" onClick={logout}>Logout</Button>
        </header>
        <Box display="flex" justifyContent="space-around" alignItems="center">
          <BasicInfo />
        </Box>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="">
              <Tab label="Companies" />
              <Tab label="Codes" />
              <Tab label="Feedbacks" />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <CompanyCreationForm open={isCompanyCreationFormOpen} handleClose={handleClose}/>
            <CompanyEditionForm company={companyToEdit} open={isCompanyEditionFormOpen} handleClose={handleClose}/>
            <div className="action">
              <Button onClick={createCompany}>Create</Button>
            </div>
            <Box sx={{ width: '100%' }}>
              {companies.length ? (<DataGrid
                rows={companies.map(companyMapper)}
                columns={companyColumns}
                disableRowSelectionOnClick
              />) : (<p>No companies</p>)}
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <CodeDetail code={codeDetail} open={isCodeDetailOpen} handleClose={handleCloseCodeDetail}/>
            <CodeCreationForm open={isCodeCreationFormOpen} handleClose={handleClose}/>
            <CodeEditionForm code={codeToEdit} open={isCodeEditionFormOpen} handleClose={handleClose}/>
            <div className="action">
              <Button onClick={createCode}>Create</Button>
            </div>
            <Box sx={{ width: '100%' }}>
              {codes.length ? (<DataGrid
                rows={codes.map(codeMapper)}
                columns={codeColumns}
                rowHeight={130}
                disableRowSelectionOnClick
              />) : (<p>No codes</p>)}
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            {!feedbacks.length && (<p>No feedbacks</p>)}
            <ul className="list feedbackList">
              {feedbacks?.map(feedback => 
                (<li key={feedback.id} className="feedbackItem">
                  <div>
                    Amount: {feedback.amount}
                  </div>
                  <div>
                    Company: {feedback?.company?.name}
                  </div>
                  <div>
                    Comment: {feedback.feedback}
                  </div>
                  <div>
                    Stars: {feedback.stars}
                  </div>
                  {feedback.payment && (<Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                    >
                      See payment details
                    </AccordionSummary>
                    <AccordionDetails>
                      <pre>{JSON.stringify(feedback.payment, null, 2)}</pre>
                    </AccordionDetails>
                  </Accordion>)}
                </li>))}
            </ul>
          </CustomTabPanel>
        </Box>
      </div>
    </>
  );
}

export default Dashboard;
