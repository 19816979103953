import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyBW5aRL5tr4wiSMci5wYjxvTKM2_cq8hm0",
    authDomain: "twipiapp.firebaseapp.com",
    projectId: "twipiapp",
    storageBucket: "twipiapp.appspot.com",
    messagingSenderId: "320714811355",
    appId: "1:320714811355:web:3c53588a78ac881d55e9fe",
    measurementId: "G-3GDGGYE852"
};
const app = initializeApp(firebaseConfig);

export default app;